import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA
} from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

//Components
import { ClientConfigComponent } from "./components/client-config/client-config.component";
import { BasicInfoComponent } from "./components/basic-info/basic-info.component";
import { ShowPreviewComponent } from "./components/show-preview/show-preview.component";

//Shared
import { NotificationsComponent } from "./shared/notifications/notifications.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { KycLoaderComponent } from "./shared/kyc-loader/kyc-loader.component";
import { NotfoundComponent } from "./shared/notfound/notfound.component";
import { AmlLoaderComponent } from "./shared/aml-loader/aml-loader.component";
import { KycTourComponent } from "./shared/kyc-tour/kyc-tour.component";
import { VerifyComponent } from "./shared/verify/verify.component";

//Modules/Packages
import { CookieModule } from "ngx-cookie";
import { ToastrModule } from "ngx-toastr";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { ArchwizardModule } from "angular-archwizard";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CustomFormsModule } from "ng2-validation";
import { PrettyJsonModule } from "angular2-prettyjson";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

import { ImageCropperModule } from 'ngx-image-cropper';
// import { PushNotificationModule } from "ng-push-notification";

//firebase
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

//Services
import { DataService } from "./services/data.service";
import { MessagingService } from "./services/messaging.service";
import { LayoutService } from "./services/layout.service";
import { AuthService } from "./services/auth.service";
import { AppService } from "./services/app.service";
import { NotificationService } from "./services/notification.service";
import { PaymentService } from "./services/payment.service";
import { TourService } from "./services/tour.service";
import { TrackingService } from "./services/tracking.service";

//Interceptor
import { ICOInterceptor, ErrorInterceptor } from "./interceptors";

//Guards
import { AuthGuard } from "./guards/auth.guard";
import { DeGuard } from "./guards/deactivate.guard";
import { AccessGuard } from "./guards/access.guard";
import { PaymentGuard } from "./guards/payment.guard";
import { SaveData } from "./guards/save-data.guard";

// Pages
import { HistoryComponent } from "./pages/history/history.component";
import { LoginComponent } from "./pages/auth/login/login.component";
import { RegisterComponent } from "./pages/auth/register/register.component";
import { PasswordComponent } from "./pages/auth/password/password.component";

// ------------------ Form Validator Directives (by @niraw) --------------------
//::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// import { PasswordValidatorDirective } from './directives/form-validator/password.directive';
// import { ConfirmPasswordDirective } from './directives/form-validator/confirm-password.directive';
// import { EmailDirective } from './directives/form-validator/email.directive';
// import { AddressValidatorDirective } from './directives/form-validator/address-validator.directive';

// import { KycGraphModule } from "./shared/kyc-graph/kyc-graph.module" ;
import { KycModalModule } from "./shared/kyc-modal/kyc-modal.module";

// Directives Module
import { FormValidatorModule } from "./directives/form-validator/form-validator.module";
import { DndModule } from "./directives/dnd/dnd.module";
import { ShowImageModule } from "./directives/show-image/show-image.module";

// Pipe Module
import { PipesModule } from "./pipes/pipes.module";

// Service Worker
// import { ServiceWorkerModule } from '@angular/service-worker';

//KYS Modules
import { DevOpsModule } from './pages/dev-ops/dev-ops.module';

// Environment
import { environment } from "../environments/environment.prod";
// import { DetailsComponent } from "./details/details.component";
// import { AlertScheduleComponent } from './components/alert-schedule/alert-schedule.component';
// import { PaymentComponent } from './pages/payment/payment.component';
import { SavaDataComponent } from "./guards/save-data-modal";
// import { OverrideReportComponent } from './pages/override-report/override-report.component';
// import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { NewReportPageComponent } from './pages/reports/new-report-page/new-report-page.component';
import { NewReportStatusDirective } from './pages/reports/new-report-page/new-report-status.directive';
import { ProfileSearchComponent } from "./pages/aml-search/profile-search/profile-search.component";
import { ProfileDetailsComponent } from "./pages/aml-search/profile-search/profile-details/profile-details.component"
import { CommonModule } from "@angular/common";
import { AmlFalsePositiveComponent } from "./pages/aml-false-positive/aml-false-positive.component";

const config = {
  apiKey: "AIzaSyDXJqmgdZy8GgMncHvijeEHfDsC_0u3yqg",
  authDomain: "kychub-cd897.firebaseapp.com",
  databaseURL: "https://kychub-cd897.firebaseio.com",
  projectId: "kychub-cd897",
  storageBucket: "",
  messagingSenderId: "739715708057",
  appId: "1:739715708057:web:2f59573e01be65a8"
};

firebase.initializeApp(config);

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationsComponent,
        HistoryComponent,
        LoginComponent,
        RegisterComponent,
        PasswordComponent,
        ClientConfigComponent,
        BasicInfoComponent,
        KycLoaderComponent,
        ShowPreviewComponent,
        NotfoundComponent,
        AmlLoaderComponent,
        KycTourComponent,
        SavaDataComponent,
        VerifyComponent,
        NewReportStatusDirective,
        NewReportPageComponent,
        ProfileSearchComponent,
        ProfileDetailsComponent,
        AmlFalsePositiveComponent
        // OverrideReportComponent
        // DetailsComponent,
        // PaymentComponent
    ],
    imports: [
        HttpClientModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        CustomFormsModule,
        DevOpsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ArchwizardModule,
        NgMultiSelectDropDownModule.forRoot(),
        TabsModule.forRoot(),
        // PushNotificationModule.forRoot(),
        // Ng2DeviceDetectorModule.forRoot(),
        // DeviceDetectorModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: "toast-top-right",
            preventDuplicates: true,
            closeButton: true
        }),
        ModalModule.forRoot(),
        CookieModule.forRoot(),
        KycModalModule,
        FormValidatorModule,
        PipesModule,
        DndModule,
        ShowImageModule,
        PrettyJsonModule,
        NgxDaterangepickerMd.forRoot(),
        ImageCropperModule,
        // AngularFireDatabaseModule,
        // AngularFireAuthModule,
        // AngularFireMessagingModule,
        // AngularFireModule.initializeApp(environment.firebase)
        // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
    ],
    // entryComponents: [KycModalComponent],
    providers: [
        AuthGuard,
        DeGuard,
        AccessGuard,
        PaymentGuard,
        SaveData,
        // WizardState,
        DataService,
        MessagingService,
        PaymentService,
        TrackingService,
        TourService,
        AuthService,
        AppService,
        NotificationService,
        LayoutService,
        { provide: "Window", useValue: window },
        { provide: "API_URL", useValue: "https://api.dev.kychub.com" },
        {
            provide: "User_Placeholder",
            useValue: "https://www.kychub.com/dashboard/assets/img/avatar/avatar.png"
        },
        { provide: HTTP_INTERCEPTORS, useClass: ICOInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
